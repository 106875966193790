[data-ez-module-totop] {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: gray;
  color: white;
  text-align: center;
  display: none;
  z-index: 100; }

[data-ez-module-totop].svg.foundation {
  font-size: 2.2rem;
  width: 4.7rem;
  height: 4.5rem;
  padding: 10px 0px; }

[data-ez-module-totop].svg.font-awesome {
  font-size: 2.2rem;
  width: 3.7rem;
  height: 3.7rem;
  padding: 10px 0px; }
