[data-ez-module-cookiedirective] {
  position: fixed;
  top: 0px;
  background-color: white;
  color: #606569;
  padding: 10px 50px;
  z-index: 1000001;
  width: 450px;
  left: calc( 50% - ( 450px / 2 )); }
  [data-ez-module-cookiedirective] .headline {
    text-align: center;
    padding-bottom: 10px; }
  [data-ez-module-cookiedirective] .message {
    text-align: center;
    margin-top: 10px;
    padding: 5px;
    border-radius: 4px; }
  [data-ez-module-cookiedirective] .message[data-ez-message="settings_saved"] {
    border: 1px solid #c3e6cb;
    background-color: #d4edda;
    color: black; }
  [data-ez-module-cookiedirective] .message[data-ez-message="allow_cookies"] {
    border: 1px solid #f5c6cb;
    background-color: #f8d7da;
    color: black; }
  [data-ez-module-cookiedirective] .options {
    margin: 0px;
    overflow: hidden;
    max-height: 300px;
    transition: max-height 1s ease-in-out; }
    [data-ez-module-cookiedirective] .options input[name="cookie_directive"] {
      margin: 0px;
      margin-right: 0.5rem;
      margin-left: 2px; }
  [data-ez-module-cookiedirective] .options.selected {
    -webkit-animation-name: cookie-directive-close-options;
    -webkit-animation-duration: 1s;
    -moz-animation-name: cookie-directive-close-options;
    -moz-animation-duration: 1s;
    -ms-animation-name: cookie-directive-close-options;
    -ms-animation-duration: 1s;
    animation-name: cookie-directive-close-options;
    animation-duration: 1s;
    max-height: 0px; }

@media screen and (max-width: 600px) {
  [data-ez-module-cookiedirective] {
    width: 100%;
    left: calc( 50% - ( 100% / 2 )); } }

[data-ez-module-cookiedirective][data-ez-open="false"] {
  transform: translateY(-100%); }
