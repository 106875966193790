[data-ez-module-expandbox] {
  position: relative;
  overflow-y: hidden; }
  [data-ez-module-expandbox] [data-ez-output] {
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-color: white; }
  [data-ez-module-expandbox] [data-ez-output] {
    cursor: pointer; }
