.lightbox-images img {
  max-height: 200px;
  max-width: 200px;
  float: left;
  border: 2px solid white;
  border-radius: 4px; }

.lightbox-images img:hover {
  cursor: pointer; }

.lightbox-images:after {
  clear: both; }

.lightbox-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px; }

.lightbox-container {
  font-size: 30px;
  text-align: center;
  width: 0px;
  height: 0px;
  background-color: white;
  margin: auto;
  border-radius: 4px;
  z-index: 1000001; }
  .lightbox-container .inner {
    position: relative;
    width: 100%;
    height: 100%; }
    .lightbox-container .inner .item {
      position: absolute;
      top: 0px;
      left: 0px;
      background-color: white;
      border-radius: 4px; }
    .lightbox-container .inner .item.video iframe {
      width: 100%;
      height: 100%; }
    .lightbox-container .inner .lightbox-buttons .previous,
    .lightbox-container .inner .lightbox-buttons .next,
    .lightbox-container .inner .lightbox-buttons .close {
      background: transparent;
      border: none;
      padding: 0px;
      margin: 0px;
      line-height: 20px; }
      .lightbox-container .inner .lightbox-buttons .previous .icon,
      .lightbox-container .inner .lightbox-buttons .next .icon,
      .lightbox-container .inner .lightbox-buttons .close .icon {
        padding: 5px;
        background-color: black;
        color: white;
        transition: transform 0.2s ease-in-out; }
      .lightbox-container .inner .lightbox-buttons .previous .icon:hover,
      .lightbox-container .inner .lightbox-buttons .next .icon:hover,
      .lightbox-container .inner .lightbox-buttons .close .icon:hover {
        cursor: pointer;
        transform: scale(1.1); }
    .lightbox-container .inner .lightbox-buttons .previous,
    .lightbox-container .inner .lightbox-buttons .next {
      position: absolute;
      width: 35%;
      top: 10px;
      bottom: 10px; }
    .lightbox-container .inner .lightbox-buttons .previous {
      left: 10px;
      text-align: left;
      padding-left: 5px; }
    .lightbox-container .inner .lightbox-buttons .next {
      right: 10px;
      text-align: right;
      padding-right: 5px; }
    .lightbox-container .inner .lightbox-buttons .close {
      position: absolute;
      top: 15px;
      right: 15px; }
    .lightbox-container .inner .lightbox-buttons .previous:hover,
    .lightbox-container .inner .lightbox-buttons .next:hover,
    .lightbox-container .inner .lightbox-buttons .close:hover {
      cursor: pointer; }
    .lightbox-container .inner .lightbox-thumbs {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      justify-content: center;
      position: fixed;
      width: 100%;
      bottom: 0px;
      left: 0px; }
      .lightbox-container .inner .lightbox-thumbs .thumb {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;
        align-items: center;
        justify-content: center;
        background-color: white; }
        .lightbox-container .inner .lightbox-thumbs .thumb img {
          max-height: 100%;
          max-width: 100%; }
      .lightbox-container .inner .lightbox-thumbs .thumb:hover {
        cursor: pointer; }
      .lightbox-container .inner .lightbox-thumbs .thumb:first-child,
      .lightbox-container .inner .lightbox-thumbs .thumb:first-child img {
        border-top-left-radius: 4px; }
      .lightbox-container .inner .lightbox-thumbs .thumb:last-child,
      .lightbox-container .inner .lightbox-thumbs .thumb:last-child img {
        border-top-right-radius: 4px; }
